import { StyledCard } from "./styles/Card.styled";
import MyForm from "../components/MyForm";
import { Link } from "react-router-dom";

export default function Card({ item: { id, title, body, image } }) {
  if (id !== 3) {
    return (
      <StyledCard layout={id % 2 === 0 && "row-reverse"}>
        <div>
          <h2>{title}</h2>
          <p>{body}</p>
          {id === 2 && (
            <Link style={{ marginLeft: "358px" }} to="/blog">
              demo
            </Link>
          )}
        </div>

        <div>
          <img src={`./images/${image}`} alt="" />
        </div>
      </StyledCard>
    );
  } else {
    return (
      <StyledCard layout={id % 2 === 0 && "row-reverse"}>
        <div>
          <h2>{title}</h2>
          <p>{body}</p>
          <img src={`./images/${image}`} alt="" />
        </div>

        <MyForm />
      </StyledCard>
    );
  }
}
