import SocialIcons from "./SocialIcons";
import { Container } from "./styles/Container.styled";
import { Flex } from "./styles/Flex.styled";
import { StyledFooter } from "./styles/Footer.styled";

export default function Footer() {
  return (
    <StyledFooter>
      <Container>
        <img src="./images/logo_white.svg" alt="" />

        <Flex>
          <ul>
            <a href="https://mjdeitz.github.io">mjdeitz.github.io</a>
          </ul>
          <ul>
            <li>Phone</li>
            <li>+1-407-748-9851</li>
          </ul>
          <ul>
            <li>Email</li>
            <li>mjdeitz@yahoo.com</li>
          </ul>

          <SocialIcons />
        </Flex>

        <p>Mike Deitzel - software developer</p>
      </Container>
    </StyledFooter>
  );
}
