import React, { useState } from "react";
import styled, { css } from "styled-components";
import axios from "axios";

const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding: 0 20px;
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

const sharedStyles = css`
  background-color: #eee;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  ${sharedStyles}
`;

const StyledTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 100px;
  resize: none;
  ${sharedStyles}
`;
const StyledButton = styled.button`
  display: block;
  background-color: #f7797d;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
`;

const initialState = {
  email: "",
  name: "",
  phone: "",
  subject: "",
  message: "",
};

function MyForm() {
  const [state, setState] = useState(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(JSON.stringify(state));
    const correctData = {};
    correctData.GuestName = state.name;
    correctData.Email = state.email;
    correctData.Phone = state.phone;
    correctData.MessageTitle = state.subject;
    correctData.Message = state.message;
    console.log(correctData);
    axios
      .post(
        "https://i43f91ptbi.execute-api.us-east-1.amazonaws.com/v1/contact",
        correctData
      )
      .then(
        (response) => {
          console.log("Response: " + JSON.stringify(response));
        },
        (error) => {
          console.log("Error " + error);
        }
      );

    const clearState = {
      email: "",
      name: "",
      phone: "",
      subject: "",
      message: "",
    };
    setState(clearState);
  };

  const handleInput = (e) => {
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState((prev) => ({ ...prev, [inputName]: value }));
  };

  return (
    <>
      <StyledFormWrapper>
        <StyledForm onSubmit={handleSubmit}>
          <StyledInput
            type="text"
            name="name"
            placeholder="Your Name*"
            value={state.name}
            required
            onChange={handleInput}
          />
          <StyledInput
            type="text"
            name="email"
            placeholder="Your Email*"
            value={state.email}
            required
            onChange={handleInput}
          />
          <StyledInput
            type="text"
            name="subject"
            placeholder="Subject*"
            value={state.subject}
            required
            onChange={handleInput}
          />
          <StyledInput
            type="text"
            name="phone"
            placeholder="Phone(optional)"
            value={state.phone}
            onChange={handleInput}
          />
          <StyledTextArea
            name="message"
            value={state.message}
            placeholder="Your Message* ..."
            required
            onChange={handleInput}
          />
          <StyledButton type="submit">Send Message</StyledButton>
        </StyledForm>
      </StyledFormWrapper>
    </>
  );
}

export default MyForm;
