import React from "react";
import Home from "./components/Home";
import Blog from "./components/Blog";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" exact element={<Blog />} />
        </Routes>
      </Router>
    );
  }
}
