const content = [
  {
    id: 1,
    title: "Education/Skills",
    body: "Education: BS Mathematics/Masters Software Development Maharishi International University.  Skills: Java, JavaScript, NodeJS, MongoDB, React, ReactNative, Angular, TypeScript, Cloud",
    image: "softint.jpg",
  },
  {
    id: 2,
    title: "Blog",
    body: "Today I am going to talk about AWS Amplify.  With AWS Amplify both mobile and web apps can be built.  Whether one prefers a CLI or working in the console with AWS Amplify both options are available. Authentication is easy using AWS IAM and AWS Cognito. Lastly, it integrates well with JavaScript, React, React Native, Angular and other top libraries.",
    image: "all.jpg",
  },
  {
    id: 3,
    title: "Contact Me",
    body: "Looking forward to hearing from you.",
    image: "boycoffee.jpg",
  },
];

export default content;
