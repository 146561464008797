import styled from "styled-components";

export const StyledBlog = styled.div`
  background-color: whitesmoke;
  padding: 1rem 2rem;
  color: ${(props) => props.color};

  p {
    font-size: 23px;
  }
`;
