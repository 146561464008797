import { Container } from "./styles/Container.styled";
import { StyledBlog } from "./styles/Blog.styled";

function Blog() {
  return (
    <Container>
      <div>
        <img src={`./images/picture45.jpg`} alt="" />
      </div>
      <h1>AWS Amplify/React Demo</h1>
      <h2>Requirements:</h2>
      <a href="https://docs.aws.amazon.com/cli/latest/userguide/getting-started-install.html">
        Install AWS CLI
      </a>
      <br />
      <br />
      <a href="https://docs.npmjs.com/downloading-and-installing-node-js-and-npm">
        Install Node.js and npm
      </a>
      <br />
      <br />
      <a href="https://github.com/git-guides/install-git">Install Git</a>
      <br />
      <br />

      <StyledBlog color="black">
        <p>Create react app. npx create-react-app amplify-basics</p>
        <p>
          Open Command Prompt type above command in folder created for this
          project
        </p>
        <p>Screen will look like below while packages are installing.</p>
      </StyledBlog>
      <div>
        <img src={`./images/blog1.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>When complete will see</p>
      </StyledBlog>
      <div>
        <img src={`./images/blog2.jpg`} alt="" />
        <br />
        <img src={`./images/blog3.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Change into directory and npm start to verify the react app starts
          will see below in browser
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/blog4.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Control-C to terminate react app for now. Select Y to terminate batch
          job.
        </p>
        <p>Open up terminal in Visual Studio Code.</p>
        <p>Begin Amplify part of demo.</p>
        <p>Type Command: amplify init </p>
        <p>
          Make sure are in directory that react app created when run (see below){" "}
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/blog5.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Next Amplify will ask questions as part of setup</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture6.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Select Y Will see below</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture7.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Select AWS profile if only have default select else can pick from list
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture8.jpg`} alt="" />
      </div>
      <div>
        <img src={`./images/picture9.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          A lot of stuff will happen here. Amplify is creating a bunch of
          services using AWS CloudFormation – it writes infrastructure as code.
          In turn creating AWS Services. IE: S3, Lambda etcetera. Let’s take a
          closer look. Log into AWS Console and search CloudFormation. Will see
          below
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture10.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Shows the stack that was created from amplify init command</p>
        <p>
          Now click on the Stack name it will take to Stack Details screen.
          Click on Events tab. Will see below. This is what’s happening behind
          the scenes that Amplify is creating.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture11.jpg`} alt="" />
      </div>
      <div>
        <img src={`./images/picture12.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          As can be seen quite a bit going on here. Done by Amplify and
          CloudFormation template. Basically Amplify used CloudFormation to
          deploy resources.
        </p>
        <p>
          Next lets go to the AWS Amplify Section. Search amplify in the search
          bar and select AWS Amplify
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture13.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Since there is only one Amplify app will see something like below</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture14.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Right now there is no front end and back end is a dev environment (see
          below)
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture15.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Switch back to Visual Studio Code and check out the files created in
          the React Folder by Amplify(see below)
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture16.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          In amplify folder is everything from AWS Amplify. In src folder is all
          files associated with the React App.
        </p>
        <p>Next lets add the api. In Terminal type command: amplify add api</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture17.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Select REST This allows for use of Lambda and API Gateway. Select
          default label. Provide a path can be anything interesting IE: books,
          movies. For this demo it is a To Do List. Enter: /todos/todoId for a
          path. Name the Lambda Function in this case TodosLambdaFunction.
          Runtime is NodeJS. Template is Hello World. Select No for advanced
          settings. Select No for edit local lambda function now.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture18.jpg`} alt="" />
      </div>
      <div>
        <img src={`./images/picture19.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Select no for Restrict API access. Select no for adding another path.
        </p>
        <p>
          Lets look at the files and see what changes have been made. Notice the
          api was added.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture20.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Also the Lambda function was created. See below for index.js file</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture21.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Edit the index.js file (see below)</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture22.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          NOTE: When first setting up a Lambda function different types of data
          get extracted like parameters or fields It’s therefore best to log out
          the event and it can be viewed in AWS CloudWatch logs. These message
          objects can have a lot of different variables so again it’s important
          to log out the data to see how to extract it from the object. For
          example in the above when event gets logged out will be able to see
          attribute pathParameters which is showing the todoId. API Gateway path
          is /todos/1 or can be any number /todos/2 etcetera. This number is the
          todoId. job.
        </p>
        <p>
          NOTE Need to have CORS enabled in order to run app on local. Be sure
          to uncomment to enable CORS.
        </p>
        <p>In Terminal Run Command: amplify push</p>
        <p>
          Amplify push shows what Amplify will deploy to AWS Basically all
          that’s happened so far is Api and Lambda are created local but now
          deployed to the cloud.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture23.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Select Y. </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture24.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Switch back to AWS Console while process does it’s thing and check out
          CloudFormation. It will show another stack(NESTED) has been created.
          The first stack is the Parent and the NESTED stack is the child.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture25.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          There can be nested stacks IE: 1 for API, 1 for database, 1 for
          authentication etcetera.
        </p>
        <p>
          To be more specific (see below) in this case it create NESTED stack
          for API and Lambda. Total of 3 stacks.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture26.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Can click on any one of the NESTED stacks to see more of what’s
          happening behind the scenes. Click on Events tab.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture27.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Under the Resources tab for the Lambda Function NESTED STACK Amplify
          created 3 resources. An IAM Role, the Function itself, and an IAM
          Policy.
        </p>
        <p>
          Open up Amplify in a separate tab and can see both API and Lambda
          Function under Categories added.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture28.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Next lets go back to Visual Studio Code the push should be finished
          now and can see what’s next to do.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture29.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          At the bottom should see a REST API. Click the link and observe what
          happens.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture30.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Browser shows message: Missing Authentication Token. This URL that was
          copied and pasted is not the correct URL. Need to add the last part of
          it (todos/1) in order to get the correct response from the Lambda
          Function.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture31.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Notice above the URL is correct and now receive the correct response.
        </p>
        <p>We’ve done quite a bit up to this point. Let’s Summarize:</p>
        <p>
          Created Amplify Project. Added API. Coded Lambda Function with a Todo
          Item. Pushed Function to Ampify AWS through the CLI. Were given a URL
          with which can test URL/Lambda Function. This is not a CRUD app. Only
          processing GET requests. This is how the function pathParameters
          variable responds and sends data back due to the values being entered
          in the URL.{" "}
        </p>
        <p>
          NOTE: Be sure to copy the URL for ease of access. Or can go into AWS
          Console and search API Gateway it is located under Stages{" "}
        </p>
        <p>
          S3 buckets another AWS Service was also created. Can check this out by
          searching S3 in AWS Console(see below)
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture32.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Can see the name amplify gave to the bucket.</p>
        <p>Click on the name to see objects in bucket.</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture33.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Go back to Visual Studio Code and run command npm install aws-amplify
          @aws-amplify/ui-react
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture34.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Next Step: install dependencies needed to call endpoint from react app
          on the front end. Backend is created. API is created. Now just need to
          install UI tool kits that integrate with react allows REST api to be
          called.
        </p>
        <p>Now lets modify React JS code.</p>
        <p>
          In order to get Amplify UI library into project place three import
          statements in the index.js file under src folder.
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture35.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Next go to App.js file.</p>
        <p>Enter below code in place of what’s already there.</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture36.jpg`} alt="" />
      </div>
      <div>
        <img src={`./images/picture37.jpg`} alt="" />
      </div>
      <div>
        <img src={`./images/picture38.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          myAPI is unique it can be found in the backend folder (see below).
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture39.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Test out the app. Run npm start in Terminal.</p>
        <p>Type a number for the Todo Id and will receive Todo From Backend.</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture40.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Next hit control c to close the React app.</p>
        <p>
          Lets run the next amplify command to launch react app to the cloud.
        </p>
        <p>Type command: amplify add hosting in terminal. </p>
        <p>Select the default option.</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture41.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Next select Manual Deployment</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture42.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>Then type command: amplify publish</p>
      </StyledBlog>
      <div>
        <img src={`./images/picture43.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          Select Y and take a few moments while the updates are applied to the
          cloud.
        </p>
        <p>
          If receive an error - ERROR in Plugin "react" was conflicted between
          "package.json » eslint-config-react-app »
        </p>
        <p>
          Remove below code from package.json then re run command amplify
          publish
        </p>
      </StyledBlog>
      <div>
        <img src={`./images/picture44.jpg`} alt="" />
      </div>
      <StyledBlog color="black">
        <p>
          If successful, will receive an amplifyapp official URL.
          https://dev.d1hsyfus0e2qnu.amplifyapp.com similar to this.
        </p>
        <p>
          To undo this project simply type command in terminal : amplify delete
          and it will remove all items created in this demo.
        </p>
        <p>
          I hope you enjoyed this demo of Basic React app with AWS Amplify
          Service.
        </p>
      </StyledBlog>
    </Container>
  );
}

export default Blog;
