import { StyledHeader, Image } from "./styles/Header.styled";
import { Container } from "./styles/Container.styled";
import { Flex } from "./styles/Flex.styled";

export default function Header() {
  return (
    <StyledHeader>
      <Container>
        <Flex>
          <div>
            <h1>Hi, I'm Mike Deitzel</h1>

            <p>
              Welcome. I'm a recent graduate of Maharishi University Software
              Development Program. I enjoy coding and seeing projects through to
              successful completion. I'm a full-stack JavaScript developer -
              backend(NodeJS, Express, MongoDB, RESTful API) and frontend(html,
              css, React, React-Native, Angular).
            </p>
          </div>

          <Image src="./images/boystatement.jpg" alt="" />
        </Flex>
      </Container>
    </StyledHeader>
  );
}
